<template>
    <div class="boxVente" v-bind:style="fbackground()">
        <div>
            <div>
                <h5  v-bind:style="fTxtColor()">{{ title }}</h5>
                <vs-divider />
                <h3  v-bind:style="fTxtColor()">{{ value }}</h3>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.boxVente{
    margin:10px;
    text-align:center;
    border-radius:5px;
    box-shadow: 0px 4px 25px 0px rgb(219, 219, 219);

    h5{
        padding-top:15px;
        height:30px;
    }
    h3{
        padding-bottom:10px;
        height:40px;
    }
}
</style>

<script>
export default {
    props: {
        title:{
            type:String,
            default: '',
        },
        value:{
            type:String,
            default: '',
        },
        background:{
            type:String,
            default: '#fff',
        },
        textColor:{
            type:String,
            default:'black'
        }
    },
    data(){
        return{
            
        }
    },
    watch:{
        $route (){
        }
    },
    mounted:function(){
    },
    methods:{
        fbackground(){
            return {
                'background-color': this.background
            }
        },
        fTxtColor(){
            return {
                'color': this.textColor
            }
        }
    }
}
</script>