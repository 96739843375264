<template>
    <div class="container">
        <h3 class="text-center"> Accessoires </h3>
        <div>
            <apexchart 
                type="line" 
                :options="options" :series="series">
            </apexchart>
        </div>
    </div>
</template>

<script>

import apexchart from 'vue-apexcharts'

export default {
    name:'seanceG',
    components:{
        apexchart,
    },
    props:[
        'accessoire'
    ],
    data(){
        return {
            options: {
                chart: {
                    id: 'vuechart-example'
                },
               
                xaxis:{
                    categories:['Jan','Fev','Mar','Avr','Mai','Juin','Jui','Aou','Sep','Oct','Nov','Dec']
                },

                stroke: {
                    curve: 'smooth'
                },

                colors: ['rgb(92, 184, 92)']
            },
            series: [{
                name: 'ca accéssoire',
                data:[],
            }],
        }
    },
    watch:{
        seance:function(){
            // console.log(" - - - -  apex seance ", this.accessoire)
            
        }
        
    },
    methods:{
        updateChart(list ) {
        
        // In the same way, update the series option
        this.series = [{
          data: list
        }]
        
      },
    }


}
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>