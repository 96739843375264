<template>
    <div class="container">
        <h3 class="text-center"> Séances </h3>
        <div>
            <apexchart 
                type="line" 
                :options="options" :series="series">
            </apexchart>
        </div>
    </div>
</template>

<script>

import apexchart from 'vue-apexcharts'

export default {
    name:'seanceG',
    components:{
        apexchart,
    },
    props:[
        'seance'
    ],
    data(){
        return {
            options: {
                chart: {
                    id: 'vuechart-example'
                },
               
                xaxis:{
                    categories:['Jan','Fev','Mar','Avr','Mai','Juin','Jui','Aou','Sep','Oct','Nov','Dec']
                },

                stroke: {
                    curve: 'smooth'
                },
            },
            series: [{
                name: 'ca séance',
                data:[],
            }],
        }
    },
    watch:{
        seance:function(){
            // console.log(" - - - -  apex seance ", this.seance)
            
        }
        
    },
    methods:
    {
        updateChart(list )
        {
            // In the same way, update the series option
            this.series = [{
            data: list
            }]
        },
    }


}
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>